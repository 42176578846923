import styles from "./Header.module.css";

export default function Header() {
  return (
    <div className={`row text-center ${styles.header}`}>
      <div className="col-md-2 offset-md-5 d-flex align-items-center justify-content-center">
        <img src="/logo.png" className={styles.mainLogo} />
        <span className={`${styles.leadTitle} ms-1 text-dark fs-1`}>Pixel</span>
      </div>
    </div>
  );
}
